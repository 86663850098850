//配件申请（需求和备机申请一致，只有提交接口不一样）
<template>
  <StandByApply from="partsApply" />
</template>

<script>
import StandByApply from '@/views/standByApply/Index.vue'
export default {
  components: { StandByApply }
}
</script>
